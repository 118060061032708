import { GetStaticProps } from "next"

import { H2 } from "styles/Type"

import Cards from "components/Cards"
import Container from "components/Container"
import FullBleedHero from "components/FullBleedHero"
import Grid from "components/Grid"
import HalfContent from "components/HalfContent"
import Section from "components/Section"
import CollectiveListing from "components/collectives/CollectiveListing"
import CTA from "components/CTA"
import classNames from "classnames"
import Accordion from "components/Accordion"
import Button from "components/Button"

export const getStaticProps: GetStaticProps = async () => {
  const { data: collectives } = await import("lib/api").then(({ API }) =>
    API.get("/api/collective/")
  )
  const { getFAQs } = await import("pages/api/faq")

  const {
    response: { data: faqs },
  } = await getFAQs(3055843)

  return {
    props: { collectives, faqs },
    revalidate: 300,
  }
}

export default function HomePage({ collectives, faqs }) {
  return (
    <>
      <FullBleedHero
        title="Powered by Us"
        content="We can create Australia’s clean energy future. One community at a time. "
        image={{
          src: "https://oxamii-app-media-prod.s3.ap-southeast-2.amazonaws.com/static/pre-register-hero.jpg",
          alt: "A young lady and older lady standing in a field",
          objectPosition: "76% 30%",
        }}
        section={{ theme: "camo" }}
        wrapperClassName="bg-green"
      />

      <Section theme="green" padding="custom" className="lg:py-40 py-14">
        <Container>
          <Grid>
            <div className="col-span-full lg:col-span-8 lg:col-start-3 text-center">
              <H2 as="p">
                <strong className="font-medium">The People&apos;s Grid</strong>{" "}
                is Australia’s revolutionary energy movement. Join us to become
                part of a local grid of households, businesses, community
                groups, and energy producers. Let’s power a greener future. This
                is grassroots energy.
              </H2>
            </div>
          </Grid>
        </Container>
      </Section>

      <HalfContent
        reverse
        subtitle="Why Us"
        title="Small switch. Giant impact."
        content="We are the new energy builders. Individuals, households with rooftop solar, and renewable energy producers unite to create a local, renewable energy grid. Together, we power The People’s Grid."
        section={{
          className: "accent-dirt-gold overflow-hidden",
          padding: "large",
        }}
        image={{
          src: "https://oxamii-app-media-prod.s3.ap-southeast-2.amazonaws.com/static/planting.jpg",
        }}
      />
      <Cards
        subtitle="The People's Grid Ecosystem"
        title="The role you play in our grid"
        cards={[
          {
            icon: "smiley",
            subtitle: "Energy Allies",
            title: "People who switch to buy energy through The People's Grid.",
            content:
              "Allies strengthen our grid and help us fund future climate and energy projects.",
          },
          {
            icon: "solar",
            subtitle: "Energy Amplifiers",
            title: "People with rooftop solar can add to the grid.",
            content:
              "Amplifiers are paid to feed excess solar into The People's Grid. They’re prosumers and help power the community.",
          },
          {
            icon: "renewable",
            subtitle: "Energy Producers",
            title:
              "Local, renewable energy producers powering our grid nationwide.",
            content:
              "Our grid’s heavy lifters. These local generators ensure a stable and renewable power supply — through solar, wind and biogas.",
          },
        ]}
        button={{
          theme: "secondary",
          label: "See how it works",
          href: "/how-it-works",
          icon: "brokenArrow",
        }}
      />
      <HalfContent
        subtitle="Collective Power"
        title="Community powers The People’s Grid"
        content="Start a Collective so your community can access local, renewable energy. You can even use it to raise funds for a common goal. An opportunity to build your own grid and power your community."
        image={{
          src: "https://oxamii-app-media-prod.s3.ap-southeast-2.amazonaws.com/static/swim_club.jpeg",
          alt: "A group of swimmers in open water",
        }}
        section={{
          className: "accent-dirt-gold overflow-hidden",
          padding: "large",
        }}
      />
      {collectives?.length > 0 && (
        <CollectiveListing
          title={
            <>
              Collectives already powering{" "}
              <strong>The People&apos;s Grid</strong>
            </>
          }
          collectives={collectives}
        />
      )}
      <div
        className={classNames(
          "accent-dirt-gold",
          collectives?.length > 0 && "bg-black"
        )}
      >
        <CTA
          title="Form your own collective and bring local, renewable energy to your community today."
          section={{
            className: "relative overflow-hidden pt-20 pb-14 lg:py-36",
            theme: "custom",
          }}
          button={{
            label: "Start a collective",
            href: "/collectives/register",
            icon: "brokenArrow",
          }}
        />
      </div>
      {faqs && (
        <Accordion
          title="Frequently Asked"
          items={faqs.map(({ body, ...item }) => ({ content: body, ...item }))}
        >
          <div className="text-center mt-14">
            <Button theme="secondary" href="/support" icon="brokenArrow">
              See all FAQs
            </Button>
          </div>
        </Accordion>
      )}
    </>
  )
}
