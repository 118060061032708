import { useRef } from "react"
import classNames from "classnames"

import useScreenEntry from "lib/useScreenEntry"
import { Title, Lead } from "styles/Type"

import Container from "components/Container"
import Grid from "components/Grid"
import Section from "components/Section"
import Button from "components/Button"
import Image from "components/Image"

import BrokenArrowRightIcon from "icons/BrokenArrowRight"

import { FullBleedHeroProps } from "./types"

const IMAGE_POSITIONS = [
  "lg:left-[5%] lg:top-1/2 lg:mt-[-130px] xl:w-[260px] xl:h-[260px] top-12 -right-2 w-[145px] h-[145px]",
  "lg:right-[10%] lg:top-16 xl:w-[180px] xl:h-[180px] right-5 bottom-40 w-[130px] h-[130px]",
  "lg:right-[5%] lg:bottom-[7rem] lg:left-auto xl:w-[220px] xl:h-[220px] left-5 bottom-24 w-[125px] h-[125px]",
]

export const FullBleedHero = ({
  title,
  content,
  button,
  images,
  image,
  section,
  wrapperClassName = "bg-off-white",
}: FullBleedHeroProps) => {
  const { onScreen, elementRef } = useScreenEntry(false, true)
  const sectionRef = useRef<HTMLDivElement>(null)
  return (
    <div className={wrapperClassName} ref={elementRef}>
      <Section
        className="relative min-h-[720px] md:min-h-[90vh] pt-6 md:pt-0 flex items-center justify-center rounded-b-[40px] lg:rounded-b-[100px] overflow-hidden"
        theme="green"
        {...section}
        ref={sectionRef}
      >
        <Container className="flex-grow">
          <Grid>
            <div className="col-span-12 sm:col-span-9 lg:col-span-6 lg:col-start-4 relative z-10 lg:text-center">
              <div className="xl:-mx-6">
                <Title className="mb-7">{title}</Title>
                <Lead>{content}</Lead>
                {button && (
                  <Button
                    icon="brokenArrow"
                    className="mt-14 lg:mt-8 w-full md:w-auto"
                    onClick={() =>
                      window?.dataLayer?.push({ event: "hero_cta" })
                    }
                    {...button}
                  />
                )}
              </div>
            </div>
          </Grid>
        </Container>
        <button
          className="absolute w-14 h-14 bg-white rounded-full flex items-center justify-center bottom-7 lg:bottom-14 z-10 text-black"
          type="button"
          onClick={() => {
            window.scrollTo(0, sectionRef.current.offsetHeight)
          }}
        >
          <span className="sr-only">Scroll down</span>
          <BrokenArrowRightIcon className="rotate-90 w-7 h-7" />
        </button>
        {images &&
          images.map((img, i) => (
            <div
              className={classNames(
                "rounded-full absolute overflow-hidden bg-forest",
                "transition duration-700",
                !onScreen && "opacity-0 motion-safe:translate-y-20",
                IMAGE_POSITIONS[i]
              )}
              style={{ transitionDelay: `${(i + 0.5) * 150}ms` }}
              key={[JSON.stringify(img), i].join("-")}
            >
              <Image {...img} width="260" height="260" priority />
            </div>
          ))}
        {image && (
          <>
            <Image {...image} layout="fill" priority />
            {/* <div className="absolute left-0 bottom-0 top-0 w-full lg:w-6/12 bg-gradient-to-r from-black opacity-40 hidden md:block" /> */}
            <div className="absolute left-0 bottom-0 right-0 h-[85%] bg-gradient-to-t via-black from-black opacity-40" />
          </>
        )}
      </Section>
    </div>
  )
}
export default FullBleedHero
