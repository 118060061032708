import Button from "components/Button"
import Container from "components/Container"
import Grid from "components/Grid"
import Section from "components/Section"
import ChevronDownIcon from "icons/ChevronDown"
import useSlider from "lib/useSlider"
import { H2 } from "styles/Type"
import CollectiveCard from "./CollectiveCard"
import { CollectiveListingProps } from "./types"

export const CollectiveListing = ({
  collectives,
  title,
  button,
  section,
}: CollectiveListingProps) => {
  const { trackRef, slideRefs, nextSlide, prevSlide, currentSlide } = useSlider(
    collectives,
    {
      rootMargin: "0px -80% 0px 200px",
    }
  )
  const chunkedCollectives = collectives.reduce((acc, c, idx) => {
    const chunk = Math.floor(idx / 2)
    acc[chunk] = acc[chunk] || []
    acc[chunk].push(c)
    return acc
  }, [])
  return (
    <Section theme="black" {...section}>
      <Container>
        <Grid className="items-center mb-10">
          <div className="col-span-full lg:col-span-6">
            <H2>{title}</H2>
          </div>
          <div className="hidden lg:col-span-6 lg:flex justify-end">
            <button
              type="button"
              className="w-12 h-12 rounded-full border border-current flex items-center justify-center mr-1.5"
              onClick={prevSlide}
              disabled={currentSlide === 0}
            >
              <ChevronDownIcon className="rotate-90 w-8 h-8" />
            </button>
            <button
              type="button"
              className="w-12 h-12 rounded-full border border-current flex items-center justify-center"
              onClick={nextSlide}
              disabled={currentSlide === chunkedCollectives.length - 1}
            >
              <ChevronDownIcon className="-rotate-90 w-8 h-8" />
            </button>
          </div>
        </Grid>
      </Container>
      <div
        className="flex hide-scrollbar scroll-smooth snap-x snap-mandatory overflow-auto"
        ref={trackRef}
      >
        {chunkedCollectives.map((collectiveChunk, idx) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            className="w-[190vw] lg:w-full flex flex-shrink-0 lg:px-8 lg:snap-start"
            ref={slideRefs.current[idx]}
          >
            {collectiveChunk.map((collective) => (
              <CollectiveCard
                {...collective}
                key={JSON.stringify(collective)}
              />
            ))}
          </div>
        ))}
      </div>
      {button && (
        <div className="text-center mt-14">
          <Button {...button} />
        </div>
      )}
    </Section>
  )
}
export default CollectiveListing
