import type { SVGProps } from "react"

export const GoopyUpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1440"
    height="459"
    viewBox="0 0 1440 459"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    {...props}
  >
    <path d="M1669.18 205.35C1739.22 205.35 1796 262.132 1796 332.175C1796 402.218 1739.22 459 1669.18 459L-228.175 459C-298.219 459 -355 402.219 -355 332.175C-355 262.132 -298.219 205.351 -228.175 205.351L-39.4811 205.351C107.115 205.351 258.969 102.002 396.657 51.6801C485.044 19.3764 597.964 -7.2173e-05 721 -7.7551e-05C844.227 -8.29375e-05 957.306 19.4366 1045.75 51.8306C1183.17 102.157 1334.7 205.35 1481.04 205.35L1669.18 205.35Z" />
  </svg>
)
export default GoopyUpIcon
