import classNames from "classnames"
import Button from "components/Button"
import Container from "components/Container"
import Grid from "components/Grid"
import Section from "components/Section"
import icons from "icons"
import useScreenEntry from "lib/useScreenEntry"
import { H2, H3, H5, P } from "styles/Type"
import { CardsProps } from "./types"

const ICON_COLOURS = ["bg-green", "bg-dirt-gold", "bg-forest text-white"]

export const Cards = ({
  title,
  subtitle,
  cards,
  button,
  section,
}: CardsProps) => {
  const { onScreen, elementRef } = useScreenEntry()
  return (
    <Section theme="dirt-gold" padding="large" {...section} ref={elementRef}>
      <Container>
        {title && (
          <Grid>
            <div
              className={classNames(
                "col-span-full lg:col-span-6 lg:col-start-4 text-center mb-10",
                "transition duration-500",
                !onScreen && "opacity-0"
              )}
            >
              <H5 as="h2" className="mb-3">
                {subtitle}
              </H5>
              <H2 as="p">{title}</H2>
            </div>
          </Grid>
        )}

        <Grid>
          {cards.map(
            (
              { icon, subtitle: cardSubtitle, title: cardTitle, content },
              idx
            ) => {
              const Icon = icons[icon]
              return (
                <div
                  key={[cardTitle, idx].join("-")}
                  className={classNames(
                    "col-span-full lg:col-span-4 bg-white rounded-[30px] shadow-2xl text-black text-center p-7",
                    "transition duration-500",
                    !onScreen && "opacity-0 motion-safe:translate-y-20"
                  )}
                  style={{ transitionDelay: `${(idx + 1) * 150}ms` }}
                >
                  {Icon && (
                    <div
                      className={classNames(
                        "w-20 h-20 rounded-full mx-auto flex items-center justify-center mb-5",
                        ICON_COLOURS[idx % ICON_COLOURS.length]
                      )}
                    >
                      <Icon className="w-10 h-10" />
                    </div>
                  )}
                  {cardSubtitle && (
                    <H5 as="h3" className="mb-7">
                      {cardSubtitle}
                    </H5>
                  )}
                  {cardTitle && (
                    <H3 as="p" className="mb-5">
                      {cardTitle}
                    </H3>
                  )}
                  {content && <P className="opacity-80">{content}</P>}
                </div>
              )
            }
          )}
        </Grid>
        {button && (
          <div
            className={classNames(
              "text-center mt-14",
              "transition duration-700 delay-700",
              !onScreen && "opacity-0 motion-safe:translate-y-20"
            )}
          >
            <Button {...button} />
          </div>
        )}
      </Container>
    </Section>
  )
}
export default Cards
