import Button from "components/Button"
import Container from "components/Container"
import Grid from "components/Grid"
import Section from "components/Section"
import GoopyUpIcon from "icons/GoopyUp"
import { H2 } from "styles/Type"

import { CTAProps } from "./props"

export const CTA = ({ section, title, button }: CTAProps) => {
  return (
    <Section
      padding="custom"
      className="relative overflow-hidden pt-20 pb-14 lg:py-36"
      {...section}
    >
      <GoopyUpIcon
        className="left-0 h-[130px] lg:h-[460px] absolute top-0 text-accent w-full"
        preserveAspectRatio="none"
      />
      <div className="bg-accent absolute lg:top-[460px] top-[130px] w-full bottom-0" />
      <Container className="relative">
        <Grid>
          <div className="col-span-full xl:col-span-6 xl:col-start-4 text-center">
            {title && <H2 className="mb-10">{title}</H2>}
            <Button {...button} />
          </div>
        </Grid>
      </Container>
    </Section>
  )
}
export default CTA
