import { useRef, createRef, useState, useEffect, useCallback } from "react"

import splitIntersectionObserverTargets from "lib/splitIntersectionObserverTargets"

type ObserverOptions = {
  rootMargin?: string
  threshold?: number | number[]
}

/**
 * `useSlider` provides a framework for keeping track of horizontal sliders
 * (using css scroll snap) and functions to scroll them programmatically
 */
export default function useSlider(
  slides: any[],
  _options: ObserverOptions,
  centered = false
) {
  const slideRefs = useRef(
    Array(slides.length)
      .fill(null)
      .map(() => createRef<HTMLDivElement>())
  )
  const trackRef = useRef<HTMLDivElement>(null)
  const { current: options } = useRef(_options)

  const [currentSlide, setCurrentSlide] = useState(0)
  const [visibleSlides, setVisibleSlides] = useState([])

  // Handling for click & drag to slide with mouse
  useEffect(() => {
    let mouseInitialX: number | null = null
    let trackInitialX: number | null = null
    const track = trackRef.current

    const onDrag = (e: MouseEvent) => {
      trackRef.current.scrollTo(trackInitialX - e.clientX + mouseInitialX, 0)
    }
    const onMouseUp = () => {
      mouseInitialX = null
      trackInitialX = null
      track.style.scrollBehavior = ""
      requestAnimationFrame(() => {
        track.style.scrollSnapType = ""
      })
      window.removeEventListener("mousemove", onDrag)
      window.removeEventListener("mouseup", onMouseUp)
    }
    const onMouseDown = (e: MouseEvent) => {
      mouseInitialX = e.clientX
      trackInitialX = track.scrollLeft
      track.style.scrollBehavior = "auto"
      track.style.scrollSnapType = "none"
      window.addEventListener("mousemove", onDrag)
      window.addEventListener("mouseup", onMouseUp)
    }

    track.addEventListener("mousedown", onMouseDown)

    return () => {
      track.removeEventListener("mousedown", onMouseDown)
      window.removeEventListener("mouseup", onMouseUp)
    }
  }, [])

  // Update visible slides as they move on + off screen
  useEffect(() => {
    if (slides.length > slideRefs.current.length) {
      slideRefs.current.push(
        ...Array(slides.length - slideRefs.current.length)
          .fill(null)
          .map(() => createRef<HTMLDivElement>())
      )
    }
    const observer = new IntersectionObserver(
      (entries) => {
        const [visibleTargets, hiddenTargets] =
          splitIntersectionObserverTargets(entries)
        if (hiddenTargets && hiddenTargets.length > 0) {
          setVisibleSlides((currentSlides) =>
            currentSlides.filter((slide) => hiddenTargets.indexOf(slide) === -1)
          )
        }
        if (visibleTargets) {
          setVisibleSlides((currentVisibleSlides) => [
            ...visibleTargets,
            ...currentVisibleSlides,
          ])
        }
      },
      { ...options, root: trackRef.current }
    )

    slideRefs.current.forEach((ref) => {
      if (!ref.current) {
        return
      }
      observer.observe(ref.current)
    })

    return () => {
      observer.disconnect()
    }
    // Re-renders every tick if you add options here
  }, [options, slides])

  // Assume the first visible slide is on the far left of screen :shrug:
  useEffect(() => {
    const [visible] = visibleSlides
    const slideIdx = slideRefs.current.findIndex(
      ({ current }) => current === visible
    )
    if (slideIdx < 0) {
      return
    }
    setCurrentSlide(slideIdx)
  }, [visibleSlides])

  const goToSlide = useCallback(
    (index: number) => {
      if (!slideRefs.current[index]) {
        console.warn(`No slide at index ${index}`)
        return
      }
      let targetLocation = slideRefs.current[index].current.offsetLeft
      if (centered) {
        targetLocation -= window.innerWidth / 2
      }

      trackRef.current.scrollTo(targetLocation, 0)
    },
    [centered]
  )

  const nextSlide = useCallback(() => {
    goToSlide(currentSlide + 1)
  }, [goToSlide, currentSlide])

  const prevSlide = useCallback(() => {
    goToSlide(currentSlide - 1)
  }, [goToSlide, currentSlide])

  return {
    slideRefs,
    trackRef,
    currentSlide,
    visibleSlides,
    goToSlide,
    nextSlide,
    prevSlide,
  }
}
