export default function splitIntersectionObserverTargets(
  entries: IntersectionObserverEntry[]
) {
  const visibleTargets = entries
    .filter(({ isIntersecting }) => isIntersecting)
    .map(({ target }) => target)
  const hiddenTargets = entries
    .filter(({ isIntersecting }) => !isIntersecting)
    .map(({ target }) => target)

  return [visibleTargets, hiddenTargets]
}
