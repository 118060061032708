import Button from "components/Button"
import Image from "components/Image"
import { H3, P } from "styles/Type"
import { Collective } from "./types"

export const CollectiveCard = ({
  name,
  hero_images,
  slug,
  collective_goal: { goal_title },
}: Collective) => {
  return (
    <article className="rounded-[40px] lg:rounded-[30px] overflow-hidden bg-white text-black lg:mx-3 mx-1 flex flex-grow flex-1 flex-col md:flex-row">
      <div className="-ml-5 w-px h-px -mb-px -mr-px snap-start lg:snap-none" />
      <div className="w-full md:w-1/2 relative flex-shrink-0 bg-mercury min-h-[280px]">
        <Image
          {...hero_images[0]}
          layout="fill"
          sizes="(min-width: 1024px) 330px, 90vw"
        />
      </div>
      <div className="pt-7 pb-3 px-5 md:py-12 md:px-7 flex flex-col flex-grow">
        <H3 className="mb-4">{name}</H3>
        <P>{goal_title}</P>
        <div className="flex-grow flex items-end justify-center md:justify-start">
          <Button theme="subtle" href={`/collectives/${slug}`}>
            View collective
          </Button>
        </div>
      </div>
    </article>
  )
}
export default CollectiveCard
